import { Col, Image, Layout, Row } from 'antd'
import { Content, Header } from 'antd/lib/layout/layout'
import Sider from 'antd/lib/layout/Sider'
import { ReactNode } from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../assets/img/logo.png'
import { RoutePath } from '../../enums/Routes'
import { isNonAuthPage } from '../../utils/urls'
import AvatarMenu from '../AvatarMenu/AvatarMenu'
import Navigation from '../Navigation/Navigation'
import SettingsMenu from '../SettingsMenu/SettingsMenu'
import styles from './MasterPage.module.scss'
import LanguageMenu from '../LanguageMenu/LanguageMenu'

type MasterPageProps = {
  children: ReactNode
}

const MasterPage = ({ children }: MasterPageProps): JSX.Element => {
  const location = useLocation()
  const isAuthenticated = isNonAuthPage(location.pathname)

  return (
    <main className={styles.wrapper}>
      <Header className={styles.header}>
        <Row className="w-100">
          <Col span={6} className={styles.logoWrap}>
            <Link to={RoutePath.WELCOMING_PAGE} className={styles.logo}>
              <Image preview={false} src={logo}/>
            </Link>
          </Col>
          {!isAuthenticated && (
            <>
              <Col span={18} className={styles.right}>
                <LanguageMenu />
                {/* <PackageMenu /> */}
                <SettingsMenu />
                <AvatarMenu />
              </Col>
            </>
          )}
        </Row>
      </Header>
      <Layout className={styles.body}>
        {!isAuthenticated && (
          <Sider breakpoint="md"
            collapsedWidth="0"
            className={styles.sider}>
            <Navigation />
          </Sider>
        )}
        <Layout className={styles.container}>
          <Content className={styles.content}>{children}</Content>
        </Layout>
      </Layout>
    </main>
  )
}

export default MasterPage
