import {
  UserOutlined,
  // FileAddOutlined,
  // PropertySafetyOutlined,
  ProjectOutlined,
  UsergroupAddOutlined,
  UserSwitchOutlined,
  PropertySafetyOutlined,
  ContainerOutlined,
  ProfileOutlined,
  HourglassOutlined,
  AccountBookOutlined,
} from '@ant-design/icons'
import { Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { RoutePath } from '../../enums/Routes'
import { isNonAuthPage } from '../../utils/urls'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { currentUserState } from '../../store/user'
import { AdminRole } from '../../enums/RoleEnum'
import { useEffect, useState } from 'react'
import API from '../../utils/api'
import { AxiosResponse } from 'axios'
import { BaseResponse } from '../../types/common/baseResponse'
import { UserMeEntity } from '../../types/entities/user'


const Navigation = (): JSX.Element => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation();
  const local = useLocation()
  const currentUser = useRecoilValue(currentUserState)
  const [menuKey, setMenuKey] = useState(1)
  const setCurrentUser = useSetRecoilState(currentUserState)
  const token = localStorage.getItem('token')
  const lang = localStorage.getItem('lang');

  useEffect(() => {
    history.listen(() => {
      if (token) {
        API.get('/Authentication/Me')
          .then(({ data }: AxiosResponse<BaseResponse<UserMeEntity>>) => {
            if (!data.error && data.result) {
              setCurrentUser(data.result)
              setMenuKey(menuKey + 1)
            }
          })
          .catch(() => {
            setCurrentUser(null)
            if (!isNonAuthPage(history.location.pathname)) {
              history.push(RoutePath.AUTH_LOGIN)
            }
          })
      }
    })
  }, [history])

  useEffect(() => {
    if (currentUser?.role !== AdminRole.Admin) {
      if (location.pathname.includes('/auditLog') || location.pathname.includes('/editors')) {
        history.push('/')
      }
    }
  }, [location])

  const isVisible = (path: string, currentUserRole: any) => {
    const isAdminOrHR = currentUserRole === AdminRole.HR || currentUserRole === AdminRole.Admin;
    const isAdminOrEditor = currentUserRole === AdminRole.Editor || currentUserRole === AdminRole.Admin;
    const isAdmin = currentUserRole === AdminRole.Admin;
    const isPackage2 = currentUser?.package === "Package12"
    const isPackage123 = currentUser?.package === "Package123"
    const isEnerjisa = currentUser?.package === "PackageEnerjisa";

    if (lang === 'en') {
      switch (path) {
        case "sitemap":
        case "banner":
        case "survey":
          return true;
        default:
          return null;
      }
    } else {
      switch (path) {
        case "sitemap":
        case "banner":
        case "survey":
        case "location":
        case "companyParams":
        case "users":
        case "appsettings":
          return true;

        case "auditLog":
        case "mediaProperty":
          return isAdmin;

        case "cms":
          return isEnerjisa && isAdminOrEditor;

        case "companies":
          return isEnerjisa;

        case "leave":
        case "nationalHoliday":
        case "payroll":
        case "pendingLeave":
          return (isPackage2 || isPackage123) && isAdminOrHR;

        case "awards":
          return currentUser?.package === "Package13" || isPackage123 || isEnerjisa;

        case "comments":
        case "administrativetask":
        case "advance":
          return isPackage2 || isPackage123;

        default:
          return true;
      }
    }
  };

  const navItems = (t: any) => [
    // {
    //   index: 1000,
    //   icon: <PartitionOutlined />,
    //   label: 'İçerikler',
    //   path: RoutePath.SITEMAP,
    //   isVisible: isVisible('sitemap', currentUser?.role)
    // },
    // {
    //   index: 1001,
    //   icon: <PictureOutlined />,
    //   label: t('title.banner_list'),
    //   path: RoutePath.BANNERS,
    //   isVisible: isVisible('banner', currentUser?.role)
    // },
    // {
    //   index: 1002,
    //   icon: <OrderedListOutlined />,
    //   label: 'Anket Listesi',
    //   path: RoutePath.SURVEYS,
    //   isVisible: isVisible('survey', currentUser?.role)
    // },
    // {
    //   index: 1003,
    //   icon: <ReconciliationOutlined />,
    //   label: 'İzinler',
    //   path: RoutePath.LEAVES,
    //   isVisible: isVisible('leave', currentUser?.role)
    // },
    // {
    //   index: 1019,
    //   icon: <ProjectOutlined />,
    //   label: t('Lokasyonlar'),
    //   path: RoutePath.LOCATIONS,
    //   isVisible: isVisible('location', currentUser?.role)
    // },
    // {
    //   index: 1004,
    //   icon: <ReconciliationOutlined />,
    //   label: 'Resmi Tatiller',
    //   path: RoutePath.NATIONAL_HOLIDAY,
    //   isVisible: isVisible('nationalHoliday', currentUser?.role)
    // },
    // currentUser?.package === 'PackageEnerjisa' && lang === 'tr' ?
    //   {
    //     index: 1005,
    //     icon: <PartitionOutlined />,
    //     label: 'Şirketler',
    //     path: RoutePath.COMPANIES,
    //     isVisible: isVisible('companies', currentUser?.role)
    //   }
    //   : {},

    // lang === 'tr' ?
    //   {
    //     index: 1006,
    //     icon: <UsergroupAddOutlined />,
    //     label: 'Şirket Parametreleri',
    //     type: 'group',
    //     isVisible: isVisible('companyParams', currentUser?.role),
    //     children: [
    //       {
    //         index: 1007,
    //         icon: <UserOutlined />,
    //         label: 'Genel Müdürlük',
    //         path: RoutePath.COMPANY_HEADQUARTER_LIST,
    //         isVisible: currentUser?.role == AdminRole.Admin
    //       },
    //       {
    //         index: 1008,
    //         icon: <UserSwitchOutlined />,
    //         label: 'Kullanıcı Tipi',
    //         path: RoutePath.COMPANIES_USERTYPE_LIST,
    //         isVisible: currentUser?.role == AdminRole.Admin
    //       },
    //       {
    //         index: 1009,
    //         icon: <UserOutlined />,
    //         label: 'Şirket',
    //         path: RoutePath.COMPANIES_UNIT_LIST,
    //         isVisible: currentUser?.role == AdminRole.Admin
    //       },
    //     ]
    //   } : {}
    // ,
    {
      index: 1009,
      icon: <HourglassOutlined />,
      label: 'Hastagler',
      path: RoutePath.HASHTAG,
      isVisible: true
    },
    {
      index: 1008,
      icon: <AccountBookOutlined />,
      label: 'Hesaplar',
      path: RoutePath.ACCOUNT,
      isVisible: true
    },
    {
      index: 1010,
      icon: <PropertySafetyOutlined />,
      label: t('title.medium_property'),
      path: RoutePath.MEDIA_PROPERTY,
      isVisible: true
    },
    // lang === 'tr' && (currentUser?.package === "Package13" || currentUser?.package === "Package123" || currentUser?.package === "PackageEnerjisa") ?
    //   {
    //     index: 1011,
    //     icon: <LikeOutlined />,
    //     label: 'Takdir İşlemleri',
    //     type: 'group',
    //     isVisible: isVisible('awards', currentUser?.role),
    //     children: [
    //       {
    //         index: 1012,
    //         icon: <LikeOutlined />,
    //         label: t('Takdir'),
    //         path: RoutePath.AWARDS,
    //         isVisible: true
    //       },
    //       {
    //         index: 1023,
    //         icon: <LikeOutlined />,
    //         label: t('Toplu Takdir'),
    //         path: RoutePath.AWARDS_UPLOAD,
    //         isVisible: true
    //       },
    //       {
    //         index: 1013,
    //         icon: <ContainerOutlined />,
    //         label: t('Takdir Hareketleri'),
    //         path: RoutePath.AWARD_TRANSACTION_LIST,
    //         isVisible: true
    //       },
    //       {
    //         index: 1014,
    //         icon: <ContainerOutlined />,
    //         label: t('Takdir Bütçesi'),
    //         path: RoutePath.AWARD_BUDGETS,
    //         isVisible: true
    //       }
    //     ]
    //   } : {},
    lang === 'tr' ?
      {
        index: 1015,
        icon: <UsergroupAddOutlined />,
        label: 'Kullanıcılar',
        type: 'group',
        isVisible: isVisible('users', currentUser?.role),
        children: [
          {
            index: 1016,
            icon: <UserOutlined />,
            label: t('title.users_list'),
            path: RoutePath.USER,
            isVisible: true
          },
          currentUser?.role === AdminRole.Admin ?
            {
              index: 1017,
              icon: <UserSwitchOutlined />,
              label: 'Editörler',
              path: RoutePath.EDITORS,
              isVisible: currentUser?.role == AdminRole.Admin
            } : {}
        ]
      } : {},
    {
      index: 1018,
      icon: <ProjectOutlined />,
      label: t('title.auditLog'),
      path: RoutePath.AUDIT_LOG,
      isVisible: isVisible('auditLog', currentUser?.role)
    },
    // {
    //   index: 1022,
    //   icon: <CommentOutlined />,
    //   label: t('Fikir Listesi'),
    //   path: RoutePath.COMMENTS,
    //   isVisible: isVisible('comments', currentUser?.role),
    // },
    // currentUser?.package !== 'PackageEnerjisa' && lang === 'tr' ?
    //   {
    //     index: 1020,
    //     icon: <ContainerOutlined />,
    //     label: t('Bordro'),
    //     path: RoutePath.PAYROLLS,
    //     isVisible: isVisible('payroll', currentUser?.role)
    //   } : {},
    {
      index: 1025,
      icon: <ProfileOutlined />,
      label: t('CMS'),
      path: RoutePath.CMS,
      isVisible: isVisible('cms', currentUser?.role)
    },
    // {
    //   index: 1021,
    //   icon: <ContainerOutlined />,
    //   label: t('İzin Onay'),
    //   path: RoutePath.PENDING_LEAVE_LIST,
    //   isVisible: isVisible('pendingLeave', currentUser?.role)
    // },
    // {
    //   index: 1028,
    //   icon: <ContainerOutlined />,
    //   label: t('Avans'),
    //   path: RoutePath.ADVANCE,
    //   isVisible: isVisible('advance', currentUser?.role)
    // },
    // {
    //   index: 1029,
    //   icon: <ContainerOutlined />,
    //   label: t('İdari İşler'),
    //   path: RoutePath.ADMINISTRATIVETASK_LIST,
    //   isVisible: isVisible('administrativetask', currentUser?.role)
    // },
    {
      index: 1030,
      icon: <ContainerOutlined />,
      label: t('App Settings'),
      path: RoutePath.APP_SETTINGS_LIST,
      isVisible: isVisible('appsettings', currentUser?.role)
    },
  ];


  let item: any = {}
  if (!isNonAuthPage(local.pathname)) {
    item = navItems(t).find(
      (item: any) => local.pathname.indexOf(item.path) !== -1
    )
  }

  return (
    <Menu defaultSelectedKeys={[`${item?.index}`]} mode="inline" key={menuKey}>
      <div style={{backgroundColor:'#038aff',height:10,width:'100%'}}></div>
      {navItems(t).map((item: any) => {
        return item.children !== undefined ? (
          <Menu.SubMenu key={item.index} icon={item.icon} title={item.label}>
            {item.children.map((subItem: any) => {
              return (
                subItem.isVisible && <Menu.Item
                  key={subItem.index}
                  icon={subItem.icon}
                  onClick={() => history.push(subItem.path)}
                >
                  {subItem.label}
                </Menu.Item>
              )
            })}
          </Menu.SubMenu>
        ) : (
          item.isVisible && <Menu.Item
            key={item.index}
            icon={item.icon}
            onClick={() => history.push(item.path)}
          >
            {item.label}
          </Menu.Item>
        )
      })}
    </Menu>
  )
}

export default Navigation
